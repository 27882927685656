.noticia-content-container {
    position: relative;
    padding: 20px;
    max-width: 1100px;
    margin: auto;
  }
  

.post-header {
    margin: 30px 0;
}

.post-title {
    margin: 0;
}

.noticia-content {
    max-width: 600px; 
    margin: auto;
    margin-bottom: 100px;
}

.noticia-content p {
    margin: 30px 0;
}

.post-description {
    display: inline;
    background: #0f3435;
    color: #fff;
    padding: 2px;
    line-height: 2em;
}

.post-tag {
    padding: 5px 0;
}

.noticia-image {
    width: 100%; 
    height: 200px; 
    object-fit: cover;
}
