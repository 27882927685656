*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0f3435;
  background: #ebebeb;
  /* min-width: 960px; */
  overflow-x:hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
  font-size: 1rem;
  line-height: 30px;
}

ul {
  list-style-type: none;
}

small {
  font-size: .7rem;
}

.pt-100 {
  padding-top: 100px; 
}

.pt-50 {
  padding-top: 50px
}

.pb-30 {
  padding-bottom: 30px; 
}

.pt-30 {
  padding-top: 30px; 
}

.mg-0{
  margin: 0;
}

.mg-20 {
  margin-top: 20px;
}

.input {
  background: 0;
  padding: 10px 20px;
  color: #0f3435;
  border: 1px solid #0f3435;
}

.light-input {
  color: #F2EFC4;
  padding: 10px 30px;
  margin-bottom: 20px;
}

input.light-input {
  border-bottom: 1px solid #F2EFC4;
}

textarea.light-input {
  font-family: inherit;
  border: 1px solid #F2EFC4;
}

.light-input::placeholder {
  color: #7b8e79;
}

.input-large {
  width: 100%;
}

.card {
  display: flex;
  background: #FFF;
  box-shadow: 0px 10px 26px -1px rgba(0,0,0,0.25);
}

.content-container {
  position: relative;
  padding: 20px;
  max-width: 1100px;
  margin: auto;
}

.bg-dark {
  background: #0f3435; 
  color: #f2efc4;
}

.bg-orange {
  background: #f27c51; 
  color: #0f3435;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-red {
  color: #f24738;
}

.btn {
  outline: 0;
  cursor: pointer;
  height: 2rem;
  padding: 5px 10px;
  /* font-size: .8rem; */
  background: none;
  color: #0f3435;
  border: 2px solid #0f3435;
  transition: .3s;
  /* margin: 10px; */
}

.btn-light {
  /* background: #f2efc4;  */
  border: 2px solid #f2efc4;
  color: #f2efc4; 
  padding: 5px 30px; 
  font-weight: bold;
  transition: .3s;
}

.btn-light:hover {
  background: #f2efc4; 
  color: #0f3435; 
  transform: scale(1.1);
}

.btn-fill {
  background: #0f3435;
  color: #f2efc4;
}

.btn-fill-light {
  background: #f2efc4;
  color: #0f3435;
  border: 0
}

.btn-fill-light:hover {
  transform: scale(1.1);
}

.btn-default {
  transition: .3s;
}

.btn-default:hover {
  /* background: #0f3435;
  color: #f2efc4; */
  transform: scale(1.1);
}

.btn-link {
  border: 0;
}

.btn-link:hover {
  border-bottom: 2px solid #0f3435;
}

.btn-link-mobile {
  color: white;
  font-size: 1.2rem;
  margin: 10px;
  border: 0;
}

.btn-link-mobile:hover {
  border-bottom: 2px solid #0f3435;
}

.btn-block {
  width: 100%;
}

.text-light {
  color: #f2efc4;
}

.section-container {
  position: relative;
  max-width: 1100px;
  padding: 20px;
  margin: auto;
}

.section-content {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  column-gap: 40px; 
  /* height:600px;  */
  margin: 100px 0;
}

.section-content div {
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.section-subtitle {
  font-Size: 2rem;
}

.section-title {
  font-Size: 3rem;
  margin: 15px 0;
}

.section-left {
  padding-bottom: 90px;
}

.section-button {
  background: 0;
  outline: 0;
  border: 0;
  padding: 15px 0;
  color: #0F3435;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 3px solid rgba(0,0,0,0);
}

.section-button:hover {
  color: #f24738;
  transform: scale(1.1);
  border-bottom: 3px solid #0f3435;
}

.section-button-light {
  color:#f2efc4;
}

.table-default {
  font-family: 'Times New Roman', Times, serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
}

.table-default td {
  padding: 3px;
}

tr:nth-child(even){
  color: #0f3435;
  background-color: #f2efc4
}

@media(max-width: 800px) {
  .table-default {
      margin-bottom: 30px;
  }   
}

@media(max-width: 800px){
  .section-title {
    font-Size: 2rem;
    text-align: left;
    margin: 15px 0;
  }

  .section-content {
      display: block;
  }

  .section-left {
    padding-bottom: 40px;
  }
}